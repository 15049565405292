export const isAuthenticated = () => {
    const token = getCookie("token");
    // console.log(token)
    if (token) {
        const jwt = parseJwt(token)
        if (Date.now() >= jwt.exp * 1000) {
            // console.log('unauthenticated - expired token')
            return false
        } else {
            // console.log('authenticated - valid token')
            return true
        }
    } else {
        // console.log('unauthenticated - no token in cookie')
        return false
    }
}

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const deleteCookie = (name) => {
    document.cookie = `${name}" + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`
}

export const parseJwt = (token) => {
    if (!token) {
        return null;
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}