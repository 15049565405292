import { createRouter, createWebHistory } from "vue-router";
import LoginView from '../views/LoginView'
import { isAuthenticated } from '../middleware' 
import store from './../store'

const myApp = "TerraPics"


const routes = [
  {
    name: "Login",
    path: '/login',
    component: LoginView,
    meta: {
      title: `${myApp} - Login`,
      requiresAuth: false
    },
    // beforeEnter: (to, from, next ) => {
    //   if(isAuthenticated()) next({ name: 'Home'});
    //   else next()
    // }
  },
  {
    name: "Register",
    path: '/register',
    component: () => import(/* webpackChunkName: "registerForm" */ '../views/register/RegisterView'),
    meta: {
      title: `${myApp} - Register`,
      requiresAuth: false
    },
    // beforeEnter: (to, from, next ) => {
    //   if(isAuthenticated()) next("/home");
    //   else next()
    // }
  },
  {
    name: "EmailConfirmed",
    path: '/register/:activationCode',
    props: true,
    component: () => import(/* webpackChunkName: "registerForm" */ '../views/register/EmailConfirmedView'),
    meta: {
      title: `${myApp} - Email Confirmation`,
      requiresAuth: false
    },
  },
  {
    name: "ConfirmEmail",
    path: '/register/confirm-email',
    component: () => import(/* webpackChunkName: "confirmationEmail" */ '../views/register/ConfirmEmailView'),
    meta: {
      title: `${myApp} - Confirmation Email Sent!`,
      requiresAuth: false
    },
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next("/home");
      } else if (store.getters.getRegistrationResponse) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    name: "Home",
    path: '/home',
    component:  () => import(/* webpackChunkName: "home" */ '../views/HomeView'),
    meta: {
      title: `${myApp} - Home`,
      requiresAuth: true
    },
    // beforeRouteEnter() {
    //   if (store.state.photosetOverviews.length === 0) {
    //       store.dispatch("getAllUserPhotosets")
    //     console.log("getAllUserPhotosets triggered from router")
    //   }
    // }
  },
  {
    path: '/photosets/:photosetId',
    component:  () => import(/* webpackChunkName: "photosetDetail" */ '../views/photoset/PhotosetDetailView'),
    props: true,
    meta: {
      title: `${myApp} - Photoset Details`,
      requiresAuth: true
    },
  },
  {
    path: '/photosets/create',
    component:  () => import(/* webpackChunkName: "createPhotoset" */ '../views/photoset/CreatePhotoset'),
    meta: {
      title: `${myApp} - Create Photoset`,
      requiresAuth: true
    },
  },
  
  {
    name: "NotFoundView", 
    path: '/:pathMatch(.*)*',
    component:  () => import(/* webpackChunkName: "notFound" */ '../views/empty-states/NotFoundView'),
    meta: {
      title: `${myApp} - Page Not Found`
    },
  },
  {
    name: "NotFoundPhotosetView", 
    path: '/photosets/:photosetId',
    component:  () => import(/* webpackChunkName: "notFound" */ '../views/empty-states/NotFoundPhotosetView'),
    meta: {
      title: `${myApp} - Not Found`,
      requiresAuth: true
    },
  },
  {
    name: "InternalServerErrorView",  // this must be the last route in the table
    path: '/',
    component: () => import(/* webpackChunkName: "internalServerError" */ '../views/empty-states/InternalServerErrorView'),
    meta: {
      title: `${myApp} - Internal Server Error`,
      requiresAuth: true
    },
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;

  const isPublicPath = to.matched.every(record => !record.meta.requiresAuth);

  // Check if the user is not authenticated and the path is not in requiresAuth: false
  if (!isAuthenticated() && !isPublicPath) {
    next({
      name: "Login",
      query: { redirect: to.fullPath }
    });
  } else if ((!isAuthenticated() && isPublicPath) || (isAuthenticated() && !isPublicPath)) {
    next();
  } else if (isAuthenticated() && isPublicPath) {
    next({ name: "Home"})
  }
});


export default router;
