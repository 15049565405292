<template>
  <section class="hero is-fullheight background-wrapper">
    <div class="hero-body is-centered columns">
      <form class="box py-6 px-5 login-form is-border-radius-24">
        <img class="logo mb-5" src="../assets/static/images/logo.svg"
             width="130"
             height="28"
             alt="Logo"
        >
        <div
            class="field has-text-centered grey-500 is-size-0 has-text-weight-bold"
        >
          Login
        </div>
        <article :class="[{'is-invisible': !errMsg }, 'message is-danger mt-2']" style="max-width: 410px;">
          <div class="message-body" >
            {{ errMessage }}
          </div>
        </article>

        <div class="field">
          <label class="has-text-weight-bold grey-600">Email</label>
          <div class="control has-icons-right">
            <input
                ref="emailInput"
                :class="[{ 'is-danger': invalidEmail }, 'input is-rounded has-background-white-ter']"
                type="email"
                name="email"
                placeholder="john@example.com"
                v-model="loginRequest.email"
                autocomplete="username"
                @blur="validateEmail"
                @focus="resetErrMsg($event.target.name)"
            />
            <div class="icon is-right" v-if="invalidEmail">
                    <span class="material-icons is-danger">
                      error_outline
                    </span>
            </div>
          </div>

          <p class="help is-danger" v-show="invalidEmail">
            Please provide a valid email address
          </p>
        </div>

        <div class="field">
          <label class="has-text-weight-bold grey-600">Password</label>
          <span class="is-pulled-right"
          ><a class="content is-small" href="#"
          >Forgot Password?</a
          ></span
          >

          <div class="control has-icons-right">
            <input
                class="input is-rounded has-background-white-ter"
                :type="passwordInputType"
                name="password"
                placeholder="Password"
                autocomplete="current-password"
                v-model="loginRequest.password"
                @focus="resetErrMsg($event.target.name)"
            />

            <div
                class="icon is-right"
                id="password-icon"
                @click="visibility = !visibility"
            >
                    <span class="material-icons-outlined">
                      visibility_{{ visibilityStatus }}
                    </span>
            </div>
          </div>
          <div class="field">
            <label class="checkbox is-size-7 mt-2">
              <div class="is-flex is-flex-direction-row is-align-items-center">
                    <span class="is-size-5">
                      <input type="checkbox" v-model="loginRequest.rememberMe"/>
                    </span>
                    <span class="ml-1 is-unselectable">
                      Remember me
                    </span>
              </div>
            </label>
          </div>
        </div>
        <div class="field has-tooltip-arrow bottomless"
             :data-tooltip="isIncompleteLogin ? incompleteLoginText : null">
          <button  :class="[{'is-loading': isLoginOngoing }, 'button has-background-gradient-primary has-text-white is-fullwidth is-rounded has-text-weight-bold is-button-interactive']"
                  @click.prevent="login" :disabled="isIncompleteLogin">
            <span>LOGIN</span>
          </button>
        </div>

        <div
            id="register-link"
            class="content has-text-centered is-size-7"
        >
          <div id="register-section">Don't have an account?
            <a class="is-underlined" @click.prevent="goToRegister">
              <strong>Register here</strong>
            </a>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
//import * as bulmaToast from 'bulma-toast'
import {mapGetters} from 'vuex';
import {isValidEmail} from '../utils';

export default {
  name: "LoginView",
  data() {
    return {
      invalidEmail: false,
      visibility: false,
      loginRequest: {
        email: "",
        password: "",
        rememberMe: false,
      }
    };
  },
  methods: {
    login() {
      this.$store.dispatch("login", this.loginRequest).then(() => {
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
        } else {
          this.$router.push('/home')
        }
      })
    },
    validateEmail() {
      this.invalidEmail = !isValidEmail(this.loginRequest.email)
    },
    resetErrMsg(focusedField) {
      if (focusedField === 'email') {
        this.invalidEmail = false
      }
      if (this.errMsg) {
        this.$store.dispatch("setErrMsg", null)
      }
    },
    goToRegister() {
      this.$router.push('/register');
    }
  },
  computed: {
    passwordInputType() {
      return this.visibility ? "text" : "password";
    },
    visibilityStatus() {
      return this.visibility ? "on" : "off";
    },
    isIncompleteLogin() {
      return this.loginRequest.email === '' || this.invalidEmail || this.loginRequest.password === ''
    },
    errMessage() {
      return this.errMsg?.message ?? "Anything interesting?"
    },
    incompleteLoginText() {
      let messages = ["Incomplete Login data\n\nPlease address the following before submitting:\n\n"];

      if (!isValidEmail(this.loginRequest.email)) {
        messages.push("• Enter a valid email address\n");
      }
      if (!this.loginRequest.password) {
        messages.push("• Enter your password\n");
      }

      return messages.join('');
    },
    ...mapGetters([
      'isLoginOngoing',
      'errMsg'
    ])
  },
  beforeUnmount() {
    if (this.errMsg) {
      this.$store.dispatch("setErrMsg", null)
    }
  }
};
</script>

<style scoped>

.background-wrapper {
  background-image: url("../assets/static/images/cool-background.svg");
  background-size: auto 100%;
}

.material-icons-outlined {
  font-size: 18px;
  color: #989898;
  width: 18px;
  pointer-events: initial;
  user-select: none
}

.login-form {
  width: 450px;
}
@media only screen and (max-width: 450px) {
  .login-form {
    width: 100%;
  }
}

.material-icons.is-danger {
  color: #f14668;
}

#password-icon {
  cursor: pointer;
}

</style>
