import { createStore } from "vuex";
import auth from './modules/auth'
import photoset from "./modules/photoset";

export default createStore({
    modules: {
        auth,
        photoset
    }
});
