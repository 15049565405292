import moment  from "moment";
export const fileSize = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const isExpired = (expirationTimestamp) => {
    moment.defaultFormat = 'YYYY-DD-MMTHH:mm:ssZ'
    return (moment() >=  moment(expirationTimestamp, 'YYYY-DD-MMTHH:mm:ssZ'))
}

export const expirationIn = (expirationTimestamp) => {
    const expiration = moment(expirationTimestamp, 'YYYY-DD-MMTHH:mm:ssZ')
    return ( isExpired(expirationTimestamp)) ? 'expired ' + moment(expiration, 'YYYY-DD-MMTHH:mm:ssZ').fromNow() : 'expires ' + moment(expiration, 'YYYY-DD-MMTHH:mm:ssZ').fromNow()
}

export const isValidEmail = (email) => {
    const regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    return regex.test(email)
}

export const createdAgo = (creationTimestamp) => {
    const creation = moment(creationTimestamp, 'YYYY-DD-MMTHH:mm:ssZ')
    return moment(creation, 'YYYY-DD-MMTHH:mm:ssZ').fromNow()
}

export const formatDuration = (milliseconds) => {
    const duration = moment.duration(milliseconds);
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());
    const seconds = Math.floor(duration.seconds());

    const fixMessage = "left\nfor your upload☕🚀";
    let message = '';

    if (hours > 0) {
        message += `${hours} hour${hours !== 1 ? 's' : ''} `;
    }
    if (minutes > 0 || hours > 0) {
        message += `${minutes} minute${minutes !== 1 ? 's' : ''} `;
    }
    if (seconds >= 0 || minutes > 0 || hours > 0) {
        message += `${seconds} second${seconds !== 1 ? 's' : ''} `;
    }

    message += fixMessage;

    return message;
};


export const evaluatePasswordStrength = (password) => {
    const regexWeak = /^.{0,7}$/;
    const regexStrong = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*"'()+,\-./:;<=>?[\]^_`{|}~]).{10,}$/;
    const regexExcellent = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*"'()+,\-./:;<=>?[\]^_`{|}~]).{15,}$/;

    // Evaluate the password strength
    if (regexWeak.test(password)) {
        return 'weak';
    } else if (regexExcellent.test(password)) {
        return 'excellent';
    } else if (regexStrong.test(password)) {
        return 'strong';
    } else {
        // If it's not weak, strong, or excellent, it falls into average by default
        return 'average';
    }
}
