import { createApp } from "vue";
import LazyLoadDirective from "./directives/LazyLoadDirective";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import veProgress from 'vue-ellipse-progress';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


require("./assets/styles/main.scss");
createApp(App).use(store).use(router).use(Toast).use(veProgress).directive("lazyload", LazyLoadDirective).mount("#app");
