import axios from "axios";
import {getCookie} from "../../../middleware";

const initialState = {
    isLoginOngoing: false,
    invalidEmail: false,
    visibility: false,
    token: null,
    errMsg: null,
    registrationResponse: null,
    isRegistrationOngoing: false
};

const state = initialState

const mutations = {
    SET_TOKEN(state, token) {
        state.token = token;
    },
    LOGIN_PENDING(state) {
        state.isLoginOngoing = true;
    },
    LOGIN_COMPLETE(state) {
        state.isLoginOngoing = false;
    },
    REGISTRATION_PENDING(state) {
        state.isRegistrationOngoing = true;
    },
    REGISTRATION_COMPLETE(state) {
        state.isRegistrationOngoing = false;
    },
    SET_ERR_MSG(state, error) {
        state.errMsg = error
    },
    SET_REGISTRATION_RESPONSE(state, payload) {
        state.registrationResponse = payload
    }
};

const actions = {
    async login({commit}, loginRequest) {
        commit("LOGIN_PENDING")

        await axios.post("/api/login", loginRequest)
            .then(
                (response) => {
                    // Delete the "token" cookie if it exists
                    if (document.cookie.includes("token=")) {
                        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    }

                    // Check if the "token" cookie doesn't exist
                    if (!document.cookie.includes("token=")) {
                        // Set the new "token" cookie with the response data
                        document.cookie = `token=${response.data.token}`;
                    }
                    commit("SET_TOKEN", response.data.token);
                    commit("LOGIN_COMPLETE");
                },
                (error) => {
                    commit("SET_ERR_MSG", error.response.data)
                    commit("LOGIN_COMPLETE");
                }
            )
    },

    async register({commit}, registerRequest) {
        commit("REGISTRATION_PENDING")

        await axios.post("/api/register", registerRequest)
            .then(
                (response) => {
                    commit("SET_REGISTRATION_RESPONSE", response.data);
                    commit("REGISTRATION_COMPLETE");
                },
                (error) => {
                    commit("SET_ERR_MSG", error.response.data)
                    commit("REGISTRATION_COMPLETE");
                    throw new Error(error.response.data.status)
                }
            )
    },

    async logout({ commit }) {
        commit("SET_TOKEN", null);
        await new Promise((resolve) => {
            const paths = ['/', '/photosets', '/register'];

            // Attempt to delete the cookie for each path
            paths.forEach(path => {
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=" + path;
            });
            resolve();
        });
    },
    setErrMsg({commit}) {
        commit("SET_ERR_MSG", null);
    },
    async getAccessToken({commit}) {
        const token = await getCookie("token")
        commit('SET_TOKEN', token)
    },
    setAccessToken({commit}, token ) {
        commit('SET_TOKEN', token)
        // Delete the "token" cookie if it exists
        if (document.cookie.includes("token=")) {
            document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }

        // Check if the "token" cookie doesn't exist
        if (!document.cookie.includes("token=")) {
            // Set the new "token" cookie with the response data
            document.cookie = `token=${token}`;
        }
    }
};

const getters = {
    token: (state) => state.token,
    isLoginOngoing: (state) => state.isLoginOngoing,
    invalidLoginCredentials: (state) => state.invalidLoginCredentials,
    isRegistrationOngoing: state => state.isRegistrationOngoing,
    errMsg: state => state.errMsg,
    getRegistrationResponse: state => state.registrationResponse
};

const authModule = {
    state,
    mutations,
    actions,
    getters,
};

export default authModule;
