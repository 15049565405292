import axios from "axios";
import router from '../../../router/index'
import { getCookie, deleteCookie } from "../../../middleware";


const initialState = {
    photosetOverviews: [],
    photosetOverviewsSearchResults: [],
    photosetOverviewsSearchMode: false,
    photosetOverviewsLoading: false,
    lPhotoCardOpen: false,
    lPhotoCardActivePhoto: null,
    uploadPhotos: [],
    selectedMPhotos: [],
    uploadPhotosSearchResults: [],
    photosetPhotosSearchResults: [],
    isUploading: false,
    errorMsg: null,
    currentUploadPhotosetId: null,
    isLoading: false,
    modals: {
        deletePhotosetModal: null,
        sharePhotosetModal: null,
        deletePhotosModal: null,
        editDownloadablePhotosModal: null,
        resetPhotosetStatusModal: null,
        editRetouchablePhotosModal: null,
        submitPhotosetModal: null
    },
    downloads: null,
    notifications: [],
    isNotificationsLoading: false,
    photosetRetoucheCandidatesLoading: [],
    estimatedUploadTimeMessage: 'Estimating your\nupload time...⏳',
    uploadedPhotosCount: 0,
    photoSearchQuery: "",
    photoSelectedTags: {
        all: true,
        downloaded: false,
        selected: false,
        unselected: false,
        retouched: false,
        retoucheCandidates: false
    },
};

const state = initialState

const mutations = {
    UPDATE_PHOTOSET_OVERVIEWS (state, payload) {
        state.photosetOverviews = payload
    },
    UPDATE_PHOTOSET_OVERVIEWS_SEARCH_RESULTS(state, payload) {
        state.photosetOverviewsSearchResults = payload
    },
    UPDATE_PHOTOSET_OVERVIEW (state, { photoset, photosetId } ) {
        const i = state.photosetOverviews.findIndex(photosetOverview => photosetOverview.id === photosetId)
        if (i > -1) state.photosetOverviews[i] = photoset 
        else state.photosetOverviews.push(photoset)
    },
    // Add photos to photoset at the moment photosetdetailview is called as by default photoset don't have photos 
    UPDATE_PHOTOSET_PHOTOS (state, { photos, photosetId }) {
        let photosetObj = state.photosetOverviews.find(photosetOverview => photosetOverview.id === photosetId)

        const hasRetoucheReady = photos.some(photo => photo.retoucheStatus === 'retouche-ready');

        if (hasRetoucheReady) {
            photos.sort((a, b) => {
                // First, sort by retoucheStatus
                if (a.retoucheStatus === 'retouche-ready' && b.retoucheStatus !== 'retouche-ready') {
                  return -1;
                }
                if (b.retoucheStatus === 'retouche-ready' && a.retoucheStatus !== 'retouche-ready') {
                  return 1;
                }
              
                // Then, sort by name
                return a.name.localeCompare(b.name);
            });
        }

        photosetObj.photos = photos
    },
    // When MPhotoCard is clicked, status is changed to true, LPhotoCard when closed is set to false 
    SET_L_PHOTOCARD_OPEN(state, status) {
        state.lPhotoCardOpen = status
    },
    // Active Photo in View receives the photo from lPhotoCardActivePhoto
    SET_L_PHOTOCARD_ACTIVE_PHOTO(state, activePhoto) {
        state.lPhotoCardActivePhoto = activePhoto
    },
    // Set the selected for upload photos 
    SET_UPLOAD_PHOTOS(state, payload) {
        state.uploadPhotos = payload
    },
    ADD_UPLOAD_PHOTOS(state, payload) {
        const array1 = state.uploadPhotos 
        const array2 = payload
        const array3 = array1.concat(array2)
        state.uploadPhotos = array3
    },

    RESET_UPLOAD_PHOTOS(state) {
        state.uploadPhotos = []
    },
    DELETE_UPLOAD_PHOTO(state, photoIndex) {
        if (photoIndex > -1) {
            state.uploadPhotos.splice(photoIndex, 1)
        }
    },
    CREATE_PHOTOSET(state, photosetId) {
        state.currentUploadPhotosetId = photosetId
    },
    SET_UPLOADING_STATUS(state, status) {
        state.isUploading = status
    },
    SET_ERROR(state, errorMsg) {
        state.errorMsg = errorMsg
    },
    RESET_ERROR(state) {
        state.errorMsg = null
    },
    DELETE_PHOTOSET(state, photosetId) {
        const i = state.photosetOverviews.findIndex(photosetOverview => photosetOverview.id === photosetId)
        if (i > -1) {
            state.photosetOverviews.splice(i, 1)
        }
    },
    UPDATE_UPLOAD_PHOTO_SEARCH_RESULTS(state, payload) {
        state.uploadPhotosSearchResults = payload
    },
    DELETE_UPLOAD_PHOTO_SEARCH_RESULT(state, index) {
        state.uploadPhotosSearchResults.splice(index, 1)
    },
    SET_APP_MODAL(state, appMod) {
        // const name = Object.keys(appMod)
        state.modals[`${Object.keys(appMod)[0]}`] = Object.values(appMod)[0]
    },
    SET_LOADING_STATUS(state, status) {
        state.isLoading = status
    },
    ADD_SELECTED_M_PHOTO(state, photo) {
        state.selectedMPhotos.push(photo)
    },
    REMOVE_SELECTED_M_PHOTO(state, photo) {
        const i = state.selectedMPhotos.findIndex(e => e.id === photo.id)
        if (i > -1 ) state.selectedMPhotos.splice(i, 1)
    },
    RESET_SELECTED_M_PHOTOS(state) {
        state.selectedMPhotos = []
    },
    DELETE_PHOTO(state, {photosetId, mPhotoId}) {
        const photosetIndex = state.photosetOverviews.findIndex(e => e.id === photosetId)
        if (photosetIndex > -1 ) {
            state.photosetOverviews[photosetIndex].photos.splice(state.photosetOverviews[photosetIndex].photos.findIndex(photo => photo.id === mPhotoId), 1)
        }
        
    },
    ADD_PHOTO_TO_PHOTOSET(state, { photosetId, payload }) {
        const photosetIndex = state.photosetOverviews.findIndex(e => e.id === photosetId) 
        if (photosetIndex > -1 ) {
            state.photosetOverviews[photosetIndex].photos.unshift(payload)
        }
    },
    UPDATE_PHOTOSET_DOWNLOADS(state, payload) {
        state.downloads = payload
    },
    EDIT_DOWNLOADABLES(state, { photosetId, payload }) {
        const photosetIndex = state.photosetOverviews.findIndex(e => e.id === photosetId)
        if (photosetIndex > -1) {
            if (payload.downloadablePhotos >= 0 && !payload.downloadAll) {
                state.photosetOverviews[photosetIndex].downloadablePhotos = payload.downloadablePhotos
                state.photosetOverviews[photosetIndex].downloadAll = false
            } else {
                state.photosetOverviews[photosetIndex].downloadAll = payload.downloadAll
            }
        }
        
    },
    EDIT_RETOUCHABLES(state, { photosetId, payload }) {
        const photosetIndex = state.photosetOverviews.findIndex(e => e.id === photosetId)
        if (photosetIndex > -1) {
            state.photosetOverviews[photosetIndex].retoucheablePhotos = payload.retoucheablePhotos
        }
    },
    EDIT_EXPIRATION(state, { photosetId, payload }) {
        const photosetIndex = state.photosetOverviews.findIndex(e => e.id === photosetId)
        if (photosetIndex > -1) {
                state.photosetOverviews[photosetIndex].expiration = payload.expiration
        }
        
    },
    SET_PHOTOSET_OVERVIEWS_SEARCH_MODE(state, status) {
        state.photosetOverviewsSearchMode = status
    },
    RESET_PHOTOSET_OVERVIEWS(state) {
        state.photosetOverviews = []
    },
    SET_PHOTOSET_OVERVIEWS_LOADING(state, status) {
        state.photosetOverviewsLoading = status
    },
    CHANGE_PHOTOSET_TYPE(state, { photosetId, payload }) {
        const photosetIndex = state.photosetOverviews.findIndex(e => e.id === photosetId)
        if (photosetIndex > -1) {
                state.photosetOverviews[photosetIndex].type = payload.type
        }
    },
    UPDATE_NOTIFICATIONS(state, payload) {
        state.notifications = payload
    },
    SET_NOTIFICATIONS_LOADING(state, status) {
        state.isNotificationsLoading = status
    },
    UPDATE_NOTIFICATION_SEEN_STATUS(state, notificationId) {
        const notificationIndex = state.notifications.findIndex(e => e.id === notificationId)
        if (notificationIndex > -1) {
            state.notifications[notificationIndex].seen = true
        }
    },
    DOWNLOAD_START_LOADING(state, photosetId) {
        state.photosetRetoucheCandidatesLoading.push(photosetId)
    },
    DOWNLOAD_STOP_LOADING(state, photosetId) {
        const index = state.photosetRetoucheCandidatesLoading.indexOf(photosetId)
        if (index > -1) {
            state.photosetRetoucheCandidatesLoading.splice(index, 1)
        }
    },
    SET_ESTIMATED_UPLOAD_TIME_MESSAGE(state, message) {
        state.estimatedUploadTimeMessage = message
    },
    SET_UPLOADED_PHOTO_COUNT(state, count) {
        state.uploadedPhotosCount = count
    },
    CHANGE_PHOTOSET_NAME(state, { photosetId, payload }) {
        const photosetIndex = state.photosetOverviews.findIndex(e => e.id === photosetId)
        if (photosetIndex > -1) {
            state.photosetOverviews[photosetIndex].name = payload.name
        }
    },
    CHANGE_PHOTOSET_STATUS(state, { photosetId, payload}) {
        const photosetIndex = state.photosetOverviews.findIndex(e => e.id === photosetId)
        if (photosetIndex > -1) {
            state.photosetOverviews[photosetIndex].status = payload.status
        }
    },
    SET_TO_ALL(state) {
        Object.keys(state.photoSelectedTags).forEach(key => {
            state.photoSelectedTags[key] = false
        })
        state.photoSelectedTags.all = true
    },
    SET_TO_DOWNLOADED(state) {
        Object.keys(state.photoSelectedTags).forEach(key => {
            state.photoSelectedTags[key] = false
        })
        state.photoSelectedTags.downloaded = true
    },
    SET_TO_SELECTED(state) {
        Object.keys(state.photoSelectedTags).forEach(key => {
            state.photoSelectedTags[key] = false
        })
        state.photoSelectedTags.selected = true

    },
    SET_TO_UNSELECTED(state) {
        Object.keys(state.photoSelectedTags).forEach(key => {
            state.photoSelectedTags[key] = false
        })
        state.photoSelectedTags.unselected = true

    },
    SET_TO_RETOUCHED(state) {
        Object.keys(state.photoSelectedTags).forEach(key => {
            state.photoSelectedTags[key] = false
        })
        state.photoSelectedTags.retouched = true
    },
    SET_TO_RETOUCHE_CANDIDATES(state) {
        Object.keys(state.photoSelectedTags).forEach(key => {
            state.photoSelectedTags[key] = false
        })
        state.photoSelectedTags.retoucheCandidates = true

    },
    SET_PHOTO_SEARCH_QUERY(state, searchQuery) {
        state.photoSearchQuery = searchQuery
    }

};

const actions = {
    getAllUserPhotosets({ commit, rootState }) {
        commit('SET_PHOTOSET_OVERVIEWS_LOADING', true)
        axios.get('/api/photosets', {
            headers: {'Authorization': `Bearer ${rootState.auth.token}`},
        } ).then((response) => {
            commit('UPDATE_PHOTOSET_OVERVIEWS', response.data)
            commit('SET_PHOTOSET_OVERVIEWS_LOADING', false)
        }).catch((err) => {
            commit('SET_PHOTOSET_OVERVIEWS_LOADING', false)
            if (err.response.status >= 500) {
                router.push({ name: "InternalServerErrorView" })
            }
        })
    },
    // Add bad scenario 
    async getAllPhotosetPhotos({ commit, rootState }, photosetId) {
         await axios.get(`/api/photosets/${photosetId}/photos`, {
            headers: {'Authorization': `Bearer ${rootState.auth.token}`},
        }).then((response) => {
            let photos = response.data
            commit('UPDATE_PHOTOSET_PHOTOS', { 
                photos, 
                photosetId,
            } )
        })
    },
   async getUserPhotoset({commit, rootState}, photosetId) {
        await axios.get(`/api/photosets/${photosetId}`, {
            headers: {'Authorization': `Bearer ${rootState.auth.token}`},
        }).then((response) => {
            let photoset = response.data
            commit('UPDATE_PHOTOSET_OVERVIEW', { photoset, photosetId,})
        }).catch((error) => {
            if (error.response.status == 404) {
                throw new Error('404');
            } else if (error.response.status >= 500) {
                console.log("There is a server problem")
                throw new Error('500');
            }
        })
    },
    setLCardOpenStatus({ commit }, status) {
        commit('SET_L_PHOTOCARD_OPEN', status)
    },
    setLPhotoCardActivePhoto({commit}, photo) {
        // receive photoId or event Photo - refactor 
        commit('SET_L_PHOTOCARD_ACTIVE_PHOTO', photo)
    },
    setUploadPhotos({ commit }, uploadPhotos) {
        commit('SET_UPLOAD_PHOTOS', uploadPhotos)
    },
    addUploadPhotos({ commit }, addedUploadPhotos) {
        commit('ADD_UPLOAD_PHOTOS', addedUploadPhotos)
    },
    resetUploadPhotos({ commit }) {
        commit('RESET_UPLOAD_PHOTOS')
    },
    deleteUploadPhoto({ commit }, photoIndex) {
        commit('DELETE_UPLOAD_PHOTO', photoIndex)
    },
    async startUpload({ commit, rootState }, photosetCreateRequest ) {
        await axios.post('/api/photosets/create', photosetCreateRequest, {
            headers: {'Authorization': `Bearer ${rootState.auth.token}`},
        }).then((response) => {
            commit('CREATE_PHOTOSET', response.data.id)
        }).catch((error) => {
            commit('SET_ERROR', error.data.message)
        })
    },
    setUploadingStatus({ commit }, status) {
        commit('SET_UPLOADING_STATUS', status)
    },

    addPhotoToPhotoset({commit}, {photosetId, photo}) {
        commit('ADD_PHOTO_TO_PHOTOSET', { photosetId: photosetId, payload: photo, })
    },

    async deletePhotoset({ commit, rootState }, photosetId) {
        commit('SET_LOADING_STATUS', true)
        await axios.delete(`/api/photosets/${photosetId}`, {
            headers: {'Authorization': `Bearer ${rootState.auth.token}`},
        }).then((response) => {
            if (response.data.status === "deleted") {
                commit('DELETE_PHOTOSET', photosetId)
            }
        }).catch((error) => {
            commit('SET_ERROR', error.data.message)
        }).finally(()=> {
            commit('SET_LOADING_STATUS', false)
        })
    },
    async searchPhotoset({ commit, rootState }, searchQuery) {
        commit('SET_PHOTOSET_OVERVIEWS_LOADING', true)
        await axios.get(`/api/photosets/search?query=${searchQuery}`, {
            headers: {'Authorization': `Bearer ${rootState.auth.token}`},
        }).then((response) => {
            commit('UPDATE_PHOTOSET_OVERVIEWS_SEARCH_RESULTS', response.data)
            commit('SET_PHOTOSET_OVERVIEWS_LOADING', false)
        })
        
    },
    resetPhotosetOverviewsSearchResults({commit}) {
        commit('UPDATE_PHOTOSET_OVERVIEWS_SEARCH_RESULTS', [])
    },
    updateUploadPhotosSearchResults({ commit, getters }, uploadPhotoSearchText ) {
        const searchResults = getters.uploadPhotos.filter(photo => photo.name.toLowerCase().includes(uploadPhotoSearchText.toLowerCase()))
        commit('UPDATE_UPLOAD_PHOTO_SEARCH_RESULTS', searchResults)
    },
    deleteUploadPhotoSearchResult({ commit }, index) {
        commit('DELETE_UPLOAD_PHOTO_SEARCH_RESULT', index)
    },
    setAppModal({commit}, appMod) {
        commit('SET_APP_MODAL', appMod)
    },
    addSelectedMPhoto({commit}, photo) {
        commit('ADD_SELECTED_M_PHOTO', photo)
    },
    removeSelectedMPhoto({commit}, photo) {
        commit('REMOVE_SELECTED_M_PHOTO', photo)
    },
    resetSelectedMPhotos({commit}) {
        commit('RESET_SELECTED_M_PHOTOS')
    },
    async deletePhotos({ commit, rootState, getters }, photosetId) {
        commit('SET_LOADING_STATUS', true)
        for(let i = 0; i < getters.selectedMPhotos.length; i++) {
            let mPhotoId = getters.selectedMPhotos[i].id
            await axios.delete(`/api/photosets/${photosetId}/photos/${getters.selectedMPhotos[i].id}`, {
                headers: {'Authorization': `Bearer ${rootState.auth.token}`},
            })
            .then(() => {
                commit('DELETE_PHOTO', {photosetId, mPhotoId,})
            })
            .catch((err)=> {
                console.log("Something went wrong: " + err)
            })
        }
        commit('SET_LOADING_STATUS', false)
    },
    getAllDownLoads({ commit, rootState }, photosetId) {
        axios.get(`/api/photosets/${photosetId}/downloads`, {
            headers: {'Authorization': `Bearer ${rootState.auth.token}`},
        } ).then((response) => {
            commit('UPDATE_PHOTOSET_DOWNLOADS', response.data)
        })
    },
    resetAllDownLoads({commit}) {
        commit('UPDATE_PHOTOSET_DOWNLOADS', null)
    },
    editDownloadables({ commit, rootState }, {photosetId, editDownloadablesObj}) {
        commit('SET_LOADING_STATUS', true)
        axios.patch(`/api/photosets/${photosetId}/downloadable`, editDownloadablesObj, {
            headers: {'Authorization': `Bearer ${rootState.auth.token}`},
        } ).then((response) => {
            commit('EDIT_DOWNLOADABLES', { photosetId: photosetId, payload: response.data, })
        }).finally(() => {
            commit('SET_LOADING_STATUS', false)
        })
    },
    editRetouchables({ commit, rootState }, {photosetId, editRetouchablesObj}) {
        commit('SET_LOADING_STATUS', true)
        axios.patch(`/api/photosets/${photosetId}/retoucheable`, editRetouchablesObj, {
            headers: {'Authorization': `Bearer ${rootState.auth.token}`},
        } ).then((response) => {
            commit('EDIT_RETOUCHABLES', { photosetId: photosetId, payload: response.data, })
        }).finally(() => {
            commit('SET_LOADING_STATUS', false)
        })
    },
    editExpiration({ commit, rootState }, {photosetId, editExpirationObj}) {
        commit('SET_LOADING_STATUS', true)
        axios.patch(`/api/photosets/${photosetId}/expiration`, editExpirationObj, {
            headers: {'Authorization': `Bearer ${rootState.auth.token}`},
        } ).then((response) => {
            commit('EDIT_EXPIRATION', { photosetId: photosetId, payload: response.data, })
        }).finally(() => {
            commit('SET_LOADING_STATUS', false)
        })
    },
    setPhotosetOverviewsSearchMode({commit}, status) {
        commit('SET_PHOTOSET_OVERVIEWS_SEARCH_MODE', status)
    },
    resetPhotosetOverviews({commit}) {
        commit('RESET_PHOTOSET_OVERVIEWS')
    },
    changePhotosetType({ commit, rootState }, {photosetId, type}) {
        axios.patch(`/api/photosets/${photosetId}/type`, { type: type}, {
            headers: {'Authorization': `Bearer ${rootState.auth.token}`},
        } ).then((response) => {
            commit('CHANGE_PHOTOSET_TYPE', { photosetId: photosetId, payload: response.data, })
        }).catch((e) => {
            console.log("error occcured: " + e)
        })
    },
    getNotifications({ commit, rootState }) {
        commit('SET_NOTIFICATIONS_LOADING', true)
        axios.get('/api/notifications', {
            headers: {'Authorization': `Bearer ${rootState.auth.token}`},
        } ).then((response) => {
            commit('UPDATE_NOTIFICATIONS', response.data)
            commit('SET_NOTIFICATIONS_LOADING', false)
        }).catch((err) => {
            commit('SET_NOTIFICATIONS_LOADING', false)
            console.log(err)

        })
    },
    updateNotificationSeenStatus({ commit, rootState }, notificationId) {
        axios.patch(`/api/notifications/${notificationId}`, { seen: true }, {
            headers: {'Authorization': `Bearer ${rootState.auth.token}`},
        } ).then(() => {
            commit('UPDATE_NOTIFICATION_SEEN_STATUS', notificationId)
        }).catch((e) => {
            console.log("error occured: " + e)
        })
    },
    async downloadRetoucheCandidates( {commit}, photosetId) {
        let downloadTimer;
        let attempts = 15;

        commit('DOWNLOAD_START_LOADING', photosetId)
        const cookieId = Date.now()
        const link = document.createElement('a');
        link.id = 'archive-download'; //give it an ID!
        link.href = `/api/photosets/${photosetId}/photos/retouche-candidates/download?q=${cookieId}`;
        link.click();
        downloadTimer = window.setInterval(() => {
            const cookieName = `fileDownloadToken_${cookieId}`
            let token = getCookie(cookieName);
            if( (token == cookieId) || (attempts === 0) ) {
                commit('DOWNLOAD_STOP_LOADING', photosetId)
                window.clearInterval( downloadTimer );
                deleteCookie(cookieName);
            }
            attempts--;
        }, 1000 );
    }, 
    setEstimatedUploadTimeMessage({commit}, message) {
        commit('SET_ESTIMATED_UPLOAD_TIME_MESSAGE', message)
    },
    setUploadedPhotoCount({commit}, count) {
        commit('SET_UPLOADED_PHOTO_COUNT', count)
    },
    changePhotosetStatus({ commit, rootState }, {photosetId, status}) {
        axios.patch(`/api/photosets/${photosetId}/status`, { status: status}, {
            headers: {'Authorization': `Bearer ${rootState.auth.token}`},
        } ).then((response) => {
            commit('CHANGE_PHOTOSET_STATUS', { photosetId: photosetId, payload: response.data, })
        }).catch((e) => {
            console.log("error occcured: " + e)
        })
    },
    async changePhotosetName({ commit, rootState }, {photosetName, photosetId}) {
        await axios.patch(`/api/photosets/${photosetId}/name`, { name: photosetName}, {
            headers: {'Authorization': `Bearer ${rootState.auth.token}`},
        } ).then((response) => {
            commit('CHANGE_PHOTOSET_NAME', { photosetId: photosetId, payload: response.data, })
        }).catch((e) => {
            console.log("error occcured: " + e)
        })
    },
    setToAll({ commit }) {
        commit('SET_TO_ALL')
    },
    setToDownloaded({ commit }) {
        commit('SET_TO_DOWNLOADED')
    },
    setToSelected({ commit }) {
        commit('SET_TO_SELECTED')
    },
    setToUnselected({ commit }) {
        commit('SET_TO_UNSELECTED')
    },
    setToRetouched({ commit }) {
        commit('SET_TO_RETOUCHED')
    },
    setToRetoucheCandidates({ commit }) {
        commit('SET_TO_RETOUCHE_CANDIDATES')
    },
    setPhotoSearchQuery({ commit }, searchQuery) {
        commit('SET_PHOTO_SEARCH_QUERY', searchQuery)
    }

};

const getters = {
    photosetOverviews: state => state.photosetOverviews,
    photosetOverviewsSearchMode: state => state.photosetOverviewsSearchMode,
    photosetOverviewsSearchResults: state => state.photosetOverviewsSearchResults,
    photosetOverviewsLoading: state => state.photosetOverviewsLoading,
    photosetFromId: (state) => (photosetId) => {
        return state.photosetOverviews.find(photosetOverview => photosetOverview.id === photosetId)
    },
    lPhotoCardOpen: state => state.lPhotoCardOpen,
    lPhotoCardActivePhoto: state => state.lPhotoCardActivePhoto,
    uploadPhotos: state => state.uploadPhotos,
    uploadPhotosSearchResults: state => state.uploadPhotosSearchResults,
    isUploading: state => state.isUploading,
    uploadPhotosTotalSize: (state) => {
        return state.uploadPhotos.reduce((acc, uploadPhoto) => {
            return uploadPhoto.size + acc 
        }, 0).toFixed(2)
    },
    errorMsg: state => state.errorMsg,
    currentUploadPhotosetId: state => state.currentUploadPhotosetId,
    isLoading: state => state.isLoading,
    modals: state => state.modals,
    selectedMPhotos: state => state.selectedMPhotos,
    isSelectedMPhoto: state => photoId => {
        return state.selectedMPhotos.find(e => e.id === photoId) ? true : false
    },
    downloads: state => state.downloads,
    notifications: state => state.notifications,
    photosetRetoucheCandidatesLoading: state => state.photosetRetoucheCandidatesLoading,
    estimatedUploadTimeMessage: state => state.estimatedUploadTimeMessage,
    uploadedPhotosCount: state => state.uploadedPhotosCount,
    photoSearchQuery: state => state.photoSearchQuery,
    photoSelectedTags: state => state.photoSelectedTags,
    getPhotoSectionSelectedTag: state => {
        for (let key in state.photoSelectedTags) {
            if (state.photoSelectedTags[key] === true) {
                return key;
            }
        }
        return null; // Return null if no attribute is true
    }
};

const photosetModule = {
  state,
  mutations,
  actions,
  getters,
};

export default photosetModule;
